.text-input {
	border: 1px solid #cacccd;
	/*height: 50px;*/
	font-size: $font-size-large;
	font-weight: 300;
	padding: $s-size;

	@media (max-width: $desktop-breakpoint) {
		width: 100%;
	}
}

.select {
	@extend .text-input;
}

.textarea {
	@extend .text-input;
	height: 10rem;
}
