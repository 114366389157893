// Colors
$dark-grey: #333333;
$grey: #666666;
$blue: #1c88bf;
$dark-blue: #364051;
$off-white: #f7f7f7;

// Font-Size
$font-size-large: 1.8rem;
$font-size-small: 1.6rem;

// Sizes
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$desktop-breakpoint: 45rem;
