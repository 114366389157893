* {
	box-sizing: border-box;
}

*::-moz-focus-inner {
	border: 0;
}

html {
	font-size: 62.5%;
}

body {
	color: $dark-grey;
	font-family: Helvetica, Arial, sans-serif;
	font-size: $m-size;
	line-height: 1.6;
}

button {
	cursor: pointer;
}

button:disabled {
	cursor: default;
}

.is-active {
	font-weight: bold;
}
