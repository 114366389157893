.input-group {
	display: flex;
	flex-direction: column;
	margin-bottom: $m-size;

	@media (min-width: $desktop-breakpoint) {
		flex-direction: row;
	}
}

.input-group__item {
	margin: 0 $s-size 0 0;

	@media (max-width: $desktop-breakpoint) {
		text-align: center;
		margin-bottom: $s-size;
	}
}
